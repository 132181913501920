//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components:{
        Gallery: () => import('@/components/Gallery/Gallery'),
        LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue')
    },
    metaInfo(){
        return {
            title:'Gallery'
        }
    },
}
